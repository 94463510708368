import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { database } from '../firebase';
import { ref, onValue, off } from 'firebase/database';

const Category = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { districtName, feilds } = useParams();

    useEffect(() => {
        const dataRef = ref(database, `App/Dis/${districtName}/Feilds/${feilds}/Sub_Feild`);

        const fetchDetailsData = async (subFieldKey) => {
            const detailsRef = ref(database, `App/Dis/${districtName}/Feilds/${feilds}/Sub_Feild/${subFieldKey}/Details`);
            return new Promise((resolve) => {
                onValue(detailsRef, (snapshot) => {
                    const details = snapshot.val();
                    resolve(details);
                }, {
                    onlyOnce: true
                });
            });
        };

        const unsubscribeFields = onValue(dataRef, async (snapshot) => {
            const fetchedData = snapshot.val();
            if (fetchedData) {
                const categoryPromises = Object.keys(fetchedData).map(async (field) => {
                    const details = await fetchDetailsData(field);
                    return {
                        id: field,
                        title: fetchedData[field].title || 'Unnamed',
                        imageUrl: fetchedData[field].imageUrl || '',
                        details: details || {},
                    };
                });

                const categoryArray = await Promise.all(categoryPromises);
                setData(categoryArray);
            } else {
                setData([]);
            }
        });

        return () => {
            off(dataRef);
            unsubscribeFields();
        };
    }, [feilds, districtName]);

    const handleDetailsClick = (details, title) => {
        if (details && Object.keys(details).length > 0) {
            navigate('/details', { 
                state: { 
                    details,
                    title 
                }
            });
        }
    };

    return (
        <div className="px-4 py-5 md:px-6 md:py-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {data.map((item) => (
                    <div 
                        key={item.id}
                        className='p-4 rounded-lg flex flex-col items-center justify-center text-center gap-2 bg-purple-500 text-white font-bold cursor-pointer hover:bg-purple-600 transition-colors'
                        onClick={() => handleDetailsClick(item.details, item.title)}
                    >
                        <h3 className="text-lg sm:text-xl md:text-2xl">{item.title}</h3>
                        {item.imageUrl && (
                            <img 
                                src={item.imageUrl} 
                                alt={item.title}
                                className='h-32 sm:h-40 md:h-48 w-auto object-contain'
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Category;
