import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Search } from 'lucide-react';
import insurance2 from '../assets/images/insurance2.jpg';
import { database } from '../firebase';
import { ref, onValue, off } from 'firebase/database';

const InsuranceDashboard = () => {
  const { districtName } = useParams(); // Get the district name from URL params
  const [data, setData] = useState([]);

  useEffect(() => {
    const dataRef = ref(database, `App/Dis/${districtName}/Feilds/`); // Adjusted path
    
    const unsubscribe = onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData) {
        const districtArray = Object.keys(fetchedData).map((field) => ({
          id: field,
          title: fetchedData[field].title || 'Unnamed', // Default name if not present
          imageUrl: fetchedData[field].imageUrl || 'Unnamed', // Default name if not present
          subscription: fetchedData[field].subscription || 'Unnamed', // Default name if not present
        }));
        setData(districtArray);
      } else {
        setData([]); // Clear data if fetchedData is null
      }
    }, (error) => {
      console.error("Error fetching data:", error);
    });

    return () => {
      off(dataRef); // Clean up listener
      unsubscribe(); // Ensure listener is removed
    };
  }, [districtName]); // Dependency on districtName

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="p-4 md:p-6">
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search here..."
            className="w-full p-4 rounded-xl bg-white shadow-sm"
          />
          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            <Search className="text-purple-400 w-6 h-6" />
          </div>
        </div>

        <div className="mb-6 rounded-xl overflow-hidden shadow-sm">
          <img 
            src={insurance2} 
            className="w-full h-[40vh] sm:h-[50vh] md:h-[65vh] object-cover" 
            alt="Insurance banner"
          />
        </div>

        <h2 className="text-2xl font-bold mb-4">Categories</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {data.length > 0 ? (
            data.map((item) => (
              <Link to={`/category/${districtName}/${item.id}`} key={item.id} className='hover:shadow-lg transition'>
                <div className='p-4 sm:p-6 rounded-xl flex flex-col items-center justify-center text-center gap-2 bg-purple-500 w-full h-60 text-white font-bold'>
                  <h3 className="text-xl">{item.title}</h3>
                  <p className="text-sm">{item.subscription}</p>
                  <img 
                    src={item.imageUrl} 
                    alt={item.title} 
                    className="h-32 w-32 object-cover rounded-md"
                  />
                </div>
              </Link>
            ))
          ) : (
            <div className='col-span-2 text-center text-gray-500'>No categories available.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsuranceDashboard;
