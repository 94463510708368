// firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
  
  apiKey: "AIzaSyAFzp9ldNPdM4yF6yFd5ybIFarBzdBSf7Y",
  authDomain: "kamgarsahayata.firebaseapp.com",
  databaseURL: "https://kamgarsahayata-default-rtdb.firebaseio.com/",
  projectId: "kamgarsahayata",
  storageBucket: "kamgarsahayata.firebasestorage.app",
  messagingSenderId: "177579302745",
  appId:"1:177579302745:web:238e42a1c70f436d74b648",
  measurementId:"G-08YZJQM7YG"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Use the app initialization for auth
const database = getDatabase(app); // Use the app initialization for database

export { auth, database };






