import React, { useEffect, useState } from 'react';
import helping from '../assets/images/helping.png';
import { database } from '../firebase';
import { ref, onValue, off } from 'firebase/database';

const Home = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const dataRef = ref(database, `App/News/`); // Adjusted path
    
    const unsubscribe = onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData) {
        const NewArray = Object.keys(fetchedData).map((field) => ({
          id: field,
          date: fetchedData[field].currentDate || 'Unnamed',
          news: fetchedData[field].newsDesc || 'Unnamed',
          newsId: fetchedData[field].newsId || 'Unnamed',
          newsImage: fetchedData[field].newsImage || 'Unnamed',
          newsTitle: fetchedData[field].newsTitle || 'Unnamed',
          viewCount: fetchedData[field].viewCount || 'Unnamed',
        }));
        setData(NewArray);
      } else {
        setData([]);
      }
    }, (error) => {
      console.error("Error fetching data:", error);
    });

    return () => {
      off(dataRef); // Clean up listener
      unsubscribe(); // Ensure listener is removed
    };
  }, []);

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <img src={helping} className="w-full h-[60vh] object-cover rounded-md" alt="Helping" />
      <h2 className="text-2xl sm:text-3xl font-semibold mb-4 text-purple-600 mt-5">Latest Job Listings</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.map(item => (
          <div key={item.id} className="bg-white shadow-md rounded-md p-4">
            <p className="font-semibold text-lg sm:text-xl">{item.newsTitle}</p>
            <h3 className="text-sm sm:text-lg font-bold text-red-500">{item.date}</h3>
            <p className="text-sm sm:text-base text-gray-700 mb-3">{item.news}</p>
            <img
  src={item.newsImage}
  className="w-full h-auto sm:h-[30vw] md:h-[25vw] lg:h-[25vw] object-cover rounded-md mb-3"
  alt={item.newsTitle}
/>

            {/* <p className="text-sm text-gray-500">{item.viewCount}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
