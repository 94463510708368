import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin, Github } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8 mt-1">
      {/* Navigation Links */}
      <div className="container mx-auto px-4">
        <nav className="flex flex-wrap justify-center gap-14 mb-8">
          <Link to="/about" className="hover:text-gray-200 transition-colors">
            ABOUT US
          </Link>
          <Link to="/termsandconditions" className="hover:text-gray-200 transition-colors">
            TERMS AND CONDITIONS
          </Link>
          <Link to="/refundpolicy" className="hover:text-gray-200 transition-colors">
            REFUND AND RETURN POLICY
          </Link>
          <Link to="/shipingpolicy" className="hover:text-gray-200 transition-colors">
            SHIPING POLICY
          </Link>
          <Link to="/contact" className="hover:text-gray-200 transition-colors">
            CONTACT US
          </Link>
          <Link to="/privacypolicy" className="hover:text-gray-200 transition-colors">
            PRIVACY POLICY
          </Link>
        </nav>

        {/* Social Media Icons */}
        <div className="flex flex-wrap justify-center gap-6 mb-8">
          <a href="#" className="hover:text-gray-200 transition-colors">
            <Facebook size={24} />
          </a>
          <a href="#" className="hover:text-gray-200 transition-colors">
            <Twitter size={24} />
          </a>
          <a href="#" className="hover:text-gray-200 transition-colors">
            <div className="w-6 h-6">G</div>
          </a>
          <a href="#" className="hover:text-gray-200 transition-colors">
            <Instagram size={24} />
          </a>
          <a href="#" className="hover:text-gray-200 transition-colors">
            <Linkedin size={24} />
          </a>
          <a href="#" className="hover:text-gray-200 transition-colors">
            <Github size={24} />
          </a>
        </div>

        {/* Copyright */}
        <div className="text-center text-sm">
          <p>© 2020 Copyright: kamgarsahayata.in</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
