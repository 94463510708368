import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const Profile= () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // useNavigate for navigation

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate('/'); // Redirect to login if no user is logged in
    }
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to login after logout
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-6 space-y-8 bg-white rounded-lg shadow-lg">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-purple-400">Profile</h2>
          {user ? (
            <div className="mt-6">
              <p className="text-lg font-semibold">Name: {user.name || 'N/A'}</p>
              <p className="text-lg font-semibold">Phone: {user.phoneNumber}</p>
              <button
                onClick={handleLogout}
                className="mt-4 w-full bg-red-400 text-white py-2 px-4 rounded-lg"
              >
                Logout
              </button>
            </div>
          ) : (
            <p className="text-gray-500">Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
