import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const details = location.state?.details;

  if (!details) {
    return (
      <div className="p-4">
        <div className="text-red-500 mb-4">No details found</div>
        <button 
          onClick={() => navigate(-1)}
          className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
        >
          Go Back
        </button>
      </div>
    );
  }

  const renderValue = (value) => {
    if (typeof value === 'object') {
      // If the value is an array, map through it and render items
      if (Array.isArray(value)) {
        return value.join(', '); // You can customize this as needed
      }
      // If the value is an object, render its entries
      return (
        <div>
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key={subKey}>
              <strong>{subKey.replace(/_/g, ' ')}: </strong>
              {subValue}
            </div>
          ))}
        </div>
      );
    }
    return value; // For strings or numbers
  };

  return (
    <div className="p-4 mx-auto max-w-screen-xl">
      <h1 className="text-2xl font-bold mb-4 text-center">Details Information</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
        {Object.entries(details).map(([key, value]) => (
          <div key={key} className="mb-4 border-b pb-2">
            <div className="p-6 rounded-xl flex flex-col items-center justify-center text-center gap-2 w-full h-auto bg-purple-100 font-bold cursor-pointer hover:bg-purple-200 transition-colors">
              {value.logoUrl && (
                <img src={renderValue(value.logoUrl)} className="h-40 w-full mt-2 rounded" alt={key} />
              )}
              {renderValue(value.address)}
              {renderValue(value.bussinessName)}
              {renderValue(value.cityName)}
              {renderValue(value.contact)}
              {renderValue(value.contact2)}
              {renderValue(value.districtName)}
              {renderValue(value.serviceProvided)}
              {renderValue(value.yourName)}
            </div>
          </div>
        ))}
      </div>
      <button 
        onClick={() => navigate(-1)}
        className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 mt-4 block mx-auto"
      >
        Go Back
      </button>
    </div>
  );
};

export default DetailsPage;
