import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { database } from '../firebase';
import { ref, onValue, off } from 'firebase/database';
import { FaUserCircle } from 'react-icons/fa';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Navbar = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [user, setUser] = useState(null); // To store the user object

  useEffect(() => {
    const dataRef = ref(database, 'App/Dis'); // Adjusted path

    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      const districtArray = Object.keys(fetchedData).map((district) => ({
        id: district,
        name: district, // Use district name directly
      }));
      setData(districtArray);
    });

    return () => {
      off(dataRef); // Clean up listener
    };
  }, []);

  useEffect(() => {
    // Check the user's authentication status using Firebase Authentication
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update the user state based on authentication status
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const handleChange = (event) => {
    const districtName = event.target.value;
    setSelectedValue(districtName);
    if (districtName) {
      navigate(`/district/${districtName}`);
    }
  };

  return (
    <nav className="bg-purple-500 p-4 sm:p-5 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4">
      {/* Logo */}
      <Link to="/">
        <div className="text-xl text-white font-bold text-center sm:text-left">
          kamgar Sahayata
        </div>
      </Link>

      {/* District Selector */}
      <select
        id="data-select"
        value={selectedValue}
        onChange={handleChange}
        className="border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-purple-500 w-full sm:w-1/3 bg-gray-50"
      >
        <option value="">Select a district</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name} {/* Display the district name */}
          </option>
        ))}
      </select>

      {/* Login Button or Profile Icon */}
      <div className="flex justify-center sm:justify-end w-full sm:w-auto">
        {!user ? (
          <Link to="/login" className="hover:underline">
            <button className="bg-white border rounded-md text-purple-600 p-2 w-40 sm:w-auto">
              Login
            </button>
          </Link>
        ) : (
          <Link to="/profile" className="sm:ml-4">
            <FaUserCircle size={30} color="white" className='mt-2' /> {/* Profile icon */}
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
