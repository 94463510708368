import React from 'react';

const Card = ({ children }) => {
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      {children}
    </div>
  );
};

const CardContent = ({ children }) => {
  return (
    <div className="p-4">
      {children}
    </div>
  );
};

const Contact = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="min-h-screen bg-white p-6">
      {/* Main Content Container */}
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        {/* <h1 className="text-4xl font-medium text-gray-800 mb-8">Contact Us</h1> */}
        
        {/* Contact Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Left Column - Contact Information */}
          <div className="md:col-span-2">
            <h2 className="text-3xl font-medium text-gray-800 mb-6">Contact Us</h2>
            
            <p className="text-gray-600 mb-8">
              We love hearing from our readers! If you have any questions, suggestions, or just want to say
              hello, please feel free to reach out to us. You can contact us using the information provided below,
              or you can fill out the contact form.
            </p>
            
            <div className="space-y-4">
              <div className="flex items-center">
                <span className="text-gray-600">Email:- </span>
                <a href="mailto:info@mstechno.in" className="ml-2 text-blue-600 hover:text-blue-800">
                  info@mstechno.in
                </a>
              </div>
              
              <div className="flex items-center">
                <span className="text-gray-600">MobileNo:- </span>
                <a href="tel:+919762509295" className="ml-2 text-blue-600 hover:text-blue-800">
                  +919762509295
                </a>
              </div>
            </div>
          </div>
          
          {/* Right Column - Recent Comments & Search */}
          <div className="space-y-6">
            {/* Search Box */}
            <Card>
              <CardContent>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full p-2 pr-12 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <button className="absolute right-2 top-1/2 -translate-y-1/2 bg-gray-700 text-white px-4 py-1 rounded-md text-sm hover:bg-gray-800">
                    Search
                  </button>
                </div>
              </CardContent>
            </Card>
            
            {/* Recent Comments */}
            <Card>
              <CardContent>
                <h3 className="text-xl font-medium text-gray-800 mb-4">Recent Comments</h3>
                <p className="text-gray-500 italic">No comments to show.</p>
              </CardContent>
            </Card>
          </div>
        </div>
        
        {/* Footer */}
        <footer className="mt-12 text-center text-gray-600 text-sm">
          <p>
            © {currentYear} MS Techno Vision • Built with{' '}
            <a href="#" className="text-blue-600 hover:text-blue-800">
              GeneratePress
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Contact;
