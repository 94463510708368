import React from 'react';
import { Search } from 'lucide-react';

const About = () => {
  return (
    <div className="max-w-6xl mx-auto p-6 bg-white min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Main Content */}
        <div className="md:col-span-2">
          <h1 className="text-4xl font-light text-gray-600 mb-8">About Us</h1>
          
          <div className="space-y-6">
            <p className="text-gray-700">
              <span className="font-semibold">mstechno.in</span> (mstechno.in) is created by news writers and bloggers. 
              The main objective of evcarnwale.in is to deliver the latest information to the readers in the fastest way. 
              Many expert writers work tirelessly day and night to create this news blog.
            </p>
            
            <p className="text-gray-700">
              The main objective of evcarnwale.in is to create a loyal base of its readers who watch online news 
              on web and mobile. We are committed to provide fast and accurate news covering national, 
              international, user interest information, funny news, astrology news, business news, sports news, 
              lifestyle news etc.
            </p>
            
            <h2 className="text-2xl font-bold text-gray-800 mt-8 mb-4">Story of mstechno.in</h2>
            
            <p className="text-gray-700">
              At the time of planning of this website, all the owners and writers were completely sure as to why 
              this news website was created. Social Media News and Technology User satisfaction is our priority, 
              this was the only reason why this brain child took almost a year to take shape.
            </p>
            
            <p className="text-gray-700">
              <span className="font-semibold">mstechno.in</span> aims to provide users with information that helps them in daily life, 
              as well as content that provides entertainment and satisfies the desire to read.
            </p>
            
            <p className="text-gray-700 font-medium mt-4">
              You will get every type of information on this website –
            </p>
            
            <ul className="list-disc list-inside text-gray-700 ml-4">
              <li>Entertainment News</li>
              <li>Tv Shows</li>
              <li>Tech News</li>
              {/* Add more news categories as needed */}
            </ul>
          </div>
        </div>
        
        {/* Sidebar */}
        <div className="md:col-span-1">
          {/* Search Box */}
          <div className="mb-8">
            <h2 className="text-xl mb-4">Search</h2>
            <div className="flex gap-2">
              <input
                type="text"
                className="flex-1 p-2 border border-gray-300 rounded"
                placeholder="Search..."
              />
              <button className="bg-black text-white px-4 py-2 rounded">
                Search
              </button>
            </div>
          </div>
          
          {/* Recent Comments */}
          <div>
            <h2 className="text-2xl mb-4">Recent Comments</h2>
            <p className="text-gray-600">No comments to show.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;