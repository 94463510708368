import React from 'react';
import { Search } from 'lucide-react';

const ShipingPolicy = () => {
  return (
    <div className="max-w-6xl mx-auto p-6 bg-white min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Main Content */}
        <div className="md:col-span-2">
          <h1 className="text-4xl font-semibold text-gray-600 mb-8">Return and Refund Policy</h1>
          
          <div className="space-y-6">
            <h1 className='text-3xl'>Shiping Policy- mstechno.in</h1>
            <p className="text-gray-700">
            MS Techno Vision , our Product Shiping Policy is based on standardized practices that strictly follow industrial norms. The Order will be shipped within 1 days from the date of Order, We put up with refund claims that are processed with vigilant parameters to make sure the decisions are justified and mutually agreeable. Our polimeasures to settle different claims, originating from different assortments of needs and various business situations. Coverage & Scope This Refund Policy covers MS Techno Vision’s action and approach toward refunds. This Refund Policy does not apply to ms techno Vision does not own or control persons that Ms Techno Vision does not employ or manage, including any third-party websites to which Ms Techno Vision’s websites link. When Do You Qualify For Refunds? When you file a complaint to Ms techno Vision, we first try to provide an ideal solution to the issue you are facing. Refund is only made in extreme circumstances.

</p>
            <p>
            While planning for refunds we check for the soundness and validity of the case, applying different conditions to it. If these condit* Development/Post-sales operations has not yet started * The issues with the project are beyond the scope of rectification/resolution/fixing * You have not violated our payment terms * You have not used any information for monetary/business benefits (gained during the course of association) * The reasons you made are valid/rational/realistic enough to qualify for refund trial * Should be able to demonstrate his/her innovative UI design and development skills and understand what makes an app add* You have presented all the proofs and evidence surfacing your refund claim * The claim does not arise from any billing dispute coming from your bank or payment vendor *Important Note: If any of the points mentioned above is found violated, your claim will be considered void. The decision on How The Refund Takes Place? 1. Pre-Refund * Refund query is processed, analyzed and checked for veracity * Match the query with the agreeable parameters and valid reference points * Arrange for refunds in permissible situations and settle fully or partly, as the case may be 2. Post-Refund * Sign a proof agreement 3. Transaction *
            </p>
            </div>
        </div>
        
        {/* Sidebar */}
        <div className="md:col-span-1">
          {/* Search Box */}
          <div className="mb-8">
            <h2 className="text-xl mb-4">Search</h2>
            <div className="flex gap-2">
              <input
                type="text"
                className="flex-1 p-2 border border-gray-300 rounded"
                placeholder="Search..."
              />
              <button className="bg-black text-white px-4 py-2 rounded">
                Search
              </button>
            </div>
          </div>
          
          {/* Recent Comments */}
          <div>
            <h2 className="text-2xl mb-4">Recent Comments</h2>
            <p className="text-gray-600">No comments to show.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipingPolicy;