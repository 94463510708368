import React, { useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../firebase';
import { User, Phone } from 'lucide-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const AuthForm = () => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // useNavigate for navigation

  const formatPhoneNumber = (phone) => {
    const cleaned = phone.replace(/\D/g, '');
    return cleaned.startsWith('91') ? `+${cleaned}` : `+91${cleaned}`;
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?91?\d{10}$/;
    return phoneRegex.test(phone);
  };

  const generateRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
    });
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setError('');
    const formattedPhone = formatPhoneNumber(mobile);

    if (!validatePhoneNumber(formattedPhone)) {
      setError('Please enter a valid 10-digit phone number');
      return;
    }

    try {
      setLoading(true);
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;
      window.confirmationResult = await signInWithPhoneNumber(auth, formattedPhone, appVerifier);
      setShowOTP(true);
      setError('');
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError(error.message || 'Error sending OTP. Please try again.');
      window.recaptchaVerifier?.clear();
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    if (!otp || otp.length !== 6) {
      setError('Please enter a valid 6-digit OTP');
      return;
    }

    try {
      setLoading(true);
      const result = await window.confirmationResult.confirm(otp);
      console.log('Successfully authenticated:', result.user);
      setIsVerified(true);
      setError('');
      navigate('/profile'); // Use navigate instead of history.push
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Invalid OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    console.log('User logged in!');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-6 space-y-8 bg-white rounded-lg shadow-lg">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-purple-400">Welcome</h2>
          <p className="mt-2 text-xl font-semibold">Sign Up Now</p>
        </div>

        {error && <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">{error}</div>}

        <form className="space-y-6" onSubmit={handleSendOTP}>
          <div>
            <div className="relative">
              <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg"
              />
            </div>
          </div>

          <div>
            <div className="relative">
              <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="tel"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile Number (10 digits)"
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg"
                maxLength={10}
              />
            </div>
          </div>

          {showOTP && (
            <div>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value.replace(/\D/g, ''))}
                placeholder="Enter 6-digit OTP"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                maxLength={6}
              />
            </div>
          )}

          <div>
            {!showOTP ? (
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-purple-400 text-white py-2 px-4 rounded-lg"
              >
                {loading ? 'Sending...' : 'Get OTP'}
              </button>
            ) : (
              <button
                onClick={verifyOTP}
                disabled={loading}
                className="w-full bg-purple-400 text-white py-2 px-4 rounded-lg"
              >
                {loading ? 'Verifying...' : 'Verify OTP'}
              </button>
            )}
          </div>
        </form>

        {isVerified && (
          <div className="mt-4">
            <button
              onClick={handleLogin}
              className="w-full bg-purple-400 text-white py-2 px-4 rounded-lg"
            >
              Login
            </button>
          </div>
        )}

        <div id="recaptcha-container"></div>
      </div>
    </div>
  );
};

export default AuthForm;
