import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';

// import LoginForm from './components/LoginForm';
import DistrictPage from './components/DistrictPage';
import Home from './components/Home';
import { getDatabase, ref, set, get, child } from 'firebase/database';
import { database } from './firebase';
import Category from './components/Category';
import InsuranceDashboard from './components/InsuranceDashboard';
import VillageDetails from './components/villageDetails';
import Detail from './components/Detail';
import DetailsPage from './components/Detail';

import AuthForm from './components/AuthForm';
import Profile from './components/Profile';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./firebase";
import TermsCondition from './components/footerpages/TermsCondition';
import RefundPolicy from './components/footerpages/RefundPolicy';
import ShipingPolicy from './components/footerpages/ShipingPolicy';
import PrivacyPolicy from './components/footerpages/PrivacyPolicy';
import Contact from './components/footerpages/Contact';
import About from './components/footerpages/About';
import Footer from './components/Footer';

function App() {
  // const [data, setData] = useState()
  // const [isRegistering, setIsRegistering] = useState(false);
  // const writeData = async () => {
  //   await set(ref(database, 'App/path'), {
  //     name: 'Example',
  //     value: 4888888888,
  //   });
  // };
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  });
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
      
        
              <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<AuthForm/>} />
        {/* <Route path="/district/:districtName" element={<DistrictPage />} /> */}
        <Route path="/district/:districtName" element={<InsuranceDashboard />} />
        
        <Route path="/category/:districtName/:feilds" element={<Category />} />
        {/* <Route path="/:villageName" element={<VillageDetails/>} /> */}
        {/* <Route path="/:details" element={<Detail />} /> */}
        <Route path="/details" element={<DetailsPage />} />
        <Route path="/termsandconditions" element={<TermsCondition/>} />
        <Route path="/refundpolicy" element={<RefundPolicy/>} />
        <Route path="/shipingpolicy" element={<ShipingPolicy />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />

      </Routes>
      <Footer/>
      </Router>
  );
}

export default App;
